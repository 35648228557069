import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Router from "next/router";

import { removeAuthToken, setAuthToken } from "../client/OneistoxApiClient";

import { UserData, DataUserProfile } from "./auth.type";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userId: "",
    userData: {} as UserData,
    userProfile: {} as Partial<DataUserProfile>,
    isAuthenticated: false,
    loading: false,
    error: "",
    emailLoginMethod: false,
    name: "",
    mobile: "",
    isUserMentor: false,
    isUserLeadMentor: false,
    cohortUserData: {},
    userLocation: "",
    userCountryCode: "",
  },
  reducers: {
    signUpStart: (state) => {
      state.loading = true;
    },
    signUpSuccess: (state, action) => {
      const { payload } = action;
      setAuthToken(payload.token);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer?.push({
        gtm: { user_id: payload.userId },
        user_id: payload.userId,
      });
      state.error = "";
      state.userId = payload.userId;
      state.emailLoginMethod = payload.emailLoginMethod;
      state.isAuthenticated = true;
      // state.isAuthenticated = payload.isAuthenticated;
    },
    signUpFail: (state, action) => {
      const { payload } = action;
      removeAuthToken();
      state.error = payload;
      state.isAuthenticated = false;
      state.loading = false;
    },
    authenticate: (state, action) => {
      const { payload } = action;

      state.emailLoginMethod = payload.userData.hashedPassword === "1";
      state.userId = payload.userData.userId;
      state.userData = { ...state.userData, ...payload.userData };
      state.isUserMentor = payload.userData?.roles?.includes("INDUSTRIAL_GUIDE");
      state.isUserLeadMentor = payload.userData?.roles?.includes("LEAD_MENTOR");
      state.userProfile = { ...state.userProfile, ...payload.userProfile };
      state.isAuthenticated = true;
      state.loading = false;
    },
    loginStart: (state) => {
      state.loading = true;
    },
    loginFailed: (state, action) => {
      const { payload } = action;
      state.error = payload;
    },
    clearErrors: (state) => {
      state.error = "";
    },
    loginSuccess: (state, action) => {
      const { payload } = action;

      setAuthToken(payload.token);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer?.push({
        gtm: { user_id: payload.userId },
        user_id: payload.userId,
      });

      state.userId = payload.userId;
      state.emailLoginMethod = payload.emailLoginMethod;
      state.isAuthenticated = true;
      // state.isAuthenticated = payload.isAuthenticated;
      state.loading = false;
    },
    logout: (state, action: PayloadAction<{ redirect: boolean; redirectPath?: string }>) => {
      const { payload } = action;
      removeAuthToken();
      if (payload?.redirect) {
        if (payload.redirectPath) {
          Router.replace(payload.redirectPath);
        } else {
          Router.push("/");
        }
      }

      state.userId = "";
      state.userData = {};
      state.isUserMentor = false;
      state.isUserLeadMentor = false;
      state.userProfile = {};
      state.emailLoginMethod = false;
      state.isAuthenticated = false;
      state.loading = false;
      state.error = "";
    },
    stepSave: (state, action) => {
      const { payload } = action;
      state.name = payload.name;
      state.mobile = payload.mobile;
    },
    updateLocation: (state, action) => {
      const { payload } = action;
      state.userLocation = payload.location;
    },
    updateUserLocationCountryCode: (state, action) => {
      const { payload } = action;
      state.userCountryCode = payload.location;
    },
  },
});
