import { GivenRecommendationStatusType } from "@/components/UserProfile/RecommendationCard";

import { appBaseCreateApi } from "./BaseQueryApiClient";
import { UserProfile } from "./types/profileApi.types";

export const userProfileClient = appBaseCreateApi({
  reducerPath: "userProfileClient",

  tagTypes: [
    "GET-USER-DATA",
    "GET-BASIC-DETAILS",
    "GET-SOFTWARE-SKILLS",
    "GET-CERTIFICATES",
    "GET-PUBLIC-CERTIFICATES",
    "GET-PUBLIC-HONOURS-AND-AWARDS",
    "GET-PUBLICATIONS",
    "GET-PUBLIC-PUBLICATIONS",
    "GET-HONOURS-AND-AWARDS",
    "GET-IMPORTANT-LINKS",
    "GET-PERSONAL-DATA",
    "GET-WORK-EXPERIENCE-DATA",
    "GET-EDUCATION-DATA",
    "GET_INTERESTS_DATA",
    "GET-PROFILE-COMPLETION-DATA",
    "GET-SELF-RECOMMENDATIONS",
    "GET-OTHERS-RECOMMENDATIONS",
    "GET-SECTION-DETAILS",
  ],

  endpoints: (builder) => ({
    // Badge section
    getBadges: builder.query({
      query: (userHash: string) => ({
        url: `v2/client/public/user/profile/badges?userHash=${userHash}`,
      }),
    }),

    // Recommendations section
    getGivenRecommendationStatus: builder.query({
      query: (userHash: string) => ({
        url: `/v1/client/profile-recommendation/check-status?receiverUserHash=${userHash}`,
      }),
    }),
    addNewRecommendation: builder.mutation({
      query: (payload: { receiverUserHash: string; content: string }) => ({
        url: `/v1/client/profile-recommendation/create`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [],
    }),
    editExistingRecommendation: builder.mutation({
      query: (payload: { recommendationId: string; content: string }) => ({
        url: `/v1/client/profile-recommendation/update`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["GET-SELF-RECOMMENDATIONS"],
    }),
    getAllRecommendations: builder.query({
      query: (userHash: string) => ({
        url: `/v1/client/profile-recommendation/fetch-all?userHash=${userHash}`,
      }),
      providesTags: ["GET-SELF-RECOMMENDATIONS"],
    }),

    getAllPublicRecommendations: builder.query({
      query: (userHash: string) => ({
        url: `/v1/client/public/profile-recommendation/all?userHash=${userHash}`,
      }),
      providesTags: ["GET-OTHERS-RECOMMENDATIONS"],
    }),
    acceptDeleteRecommendation: builder.mutation({
      query: (payload: { recommendationId: string; status: GivenRecommendationStatusType }) => ({
        url: `/v1/client/profile-recommendation/action`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["GET-SELF-RECOMMENDATIONS", "GET-BASIC-DETAILS"],
    }),
    showHideRecommendation: builder.mutation({
      query: (payload: { recommendationId: string; isShown: boolean }) => ({
        url: `/v1/client/profile-recommendation/toggle-visibility`,
        method: "PUT",
        body: payload,
      }),
    }),
    upvoteRecommendation: builder.mutation({
      query: (payload: { recommendationId: string }) => ({
        url: `/v1/client/profile-recommendation/upvote`,
        method: "POST",
        body: payload,
      }),
    }),

    getUserData: builder.query({
      query: (userId: string) => ({
        url: `/v1/client/${userId}`,
      }),
      providesTags: ["GET-USER-DATA"],
    }),
    getSoftwareKnowledgeSkills: builder.query({
      query: (userHash) => ({
        url: `/v2/client/public/user/profile/skills?userHash=${userHash}`,
      }),
      providesTags: ["GET-SOFTWARE-SKILLS"],
    }),
    // getCertificates: builder.query({
    //   query: (userId: string) => ({
    //     url: `/v2/client/user/certificates?userId=${userId}`,
    //   }),
    //   providesTags: ["GET-CERTIFICATES"],
    // }),
    // getCertificateById: builder.query({
    //   query: (certificateId: string) => ({
    //     url: `/v2/client/user/certificate/${certificateId}`,
    //   }),
    // }),
    // getPublicCertificateById: builder.query({
    //   query: (payload: { certificateId: string; userId: number }) => ({
    //     url: `/v2/client/public/user/certificate/${payload.certificateId}?userId=${payload.userId}`,
    //   }),
    // }),
    // getPublicCertificates: builder.query({
    //   query: (userId: string) => ({
    //     url: `/v2/client/public/user/certificates?userId=${userId}`,
    //   }),
    //   providesTags: ["GET-PUBLIC-CERTIFICATES"],
    // }),
    // createCertificate: builder.mutation({
    //   query: (payload: {
    //     name: string;
    //     organization: string;
    //     issueDate: string;
    //     credentialId: string;
    //     credentialUrl: string;
    //   }) => ({
    //     url: `/v2/client/user/certificate`,
    //     method: "POST",
    //     body: payload,
    //   }),
    //   invalidatesTags: ["GET-SECTION-DETAILS"],
    // }),
    // updateCertificate: builder.mutation({
    //   query: (payload: {
    //     certificateId: string;
    //     name?: string;
    //     organization?: string;
    //     issueDate?: string;
    //     credentialId?: string;
    //     credentialUrl?: string;
    //   }) => ({
    //     url: `/v2/client/user/certificate/${payload.certificateId}`,
    //     body: payload,
    //     method: "PUT",
    //   }),
    //   invalidatesTags: ["GET-SECTION-DETAILS"],
    // }),
    // deleteCertificate: builder.mutation({
    //   query: (certificateId: string) => ({
    //     url: `/v2/client/user/certificate/${certificateId}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["GET-SECTION-DETAILS"],
    // }),
    someChange: builder.mutation({
      query: (userJobData) => ({
        url: `/v1/client/hiring/user/user-job`,
        method: "PUT",
        body: userJobData,
      }),
      invalidatesTags: ["GET-USER-DATA"],
    }),

    // Basic-details-section
    // getBasicDetailsData: builder.query<UserProfile, string>({
    //   query: (userId: string) => ({
    //     url: `/v2/client/public/user/profile/basic-details?userId=${userId}`,
    //     method: "GET",
    //   }),
    //   providesTags: ["GET-BASIC-DETAILS"],
    // }),
    // updateBasicDetails: builder.mutation({
    //   query: (basicDetails) => ({
    //     url: `/v2/client/user/profile/basic-details`,
    //     method: "PUT",
    //     body: basicDetails,
    //   }),
    //   invalidatesTags: [
    //     "GET-BASIC-DETAILS",
    //     "GET-PROFILE-COMPLETION-DATA",
    //     "GET-WORK-EXPERIENCE-DATA",
    //   ],
    // }),
    applaudUser: builder.mutation({
      query: (args) => {
        const { userHash } = args;
        return {
          url: `/v2/client/public/user/profile/applaud?userHash=${userHash}`,
          method: "POST",
          body: {},
        };
      },
      invalidatesTags: ["GET-BASIC-DETAILS"],
    }),
    removeApplaud: builder.mutation({
      query: (args) => {
        const { userHash } = args;
        return {
          url: `/v2/client/public/user/profile/applaud?userHash=${userHash}`,
          method: "DELETE",
          body: {},
        };
      },
      invalidatesTags: ["GET-BASIC-DETAILS"],
    }),

    // Profile-completion prompt section
    getProfileCompletionData: builder.query({
      query: (userHash: string) => {
        return {
          url: `/v2/client/public/user/profile/completion-score?userHash=${userHash}`,
          method: "GET",
        };
      },
      providesTags: ["GET-PROFILE-COMPLETION-DATA"],
    }),
    updateProfileCompletionData: builder.mutation({
      query: (payload) => ({
        url: `/v2/client/user/profile/profile-completion`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: [
        "GET-BASIC-DETAILS",
        "GET-IMPORTANT-LINKS",
        "GET-PERSONAL-DATA",
        "GET-EDUCATION-DATA",
        "GET-PROFILE-COMPLETION-DATA",
      ],
    }),

    // Important-link-section
    // getImportantLink: builder.query({
    //   query: (userId: string) => {
    //     return {
    //       url: `/v2/client/user/important-links?userId=${userId}`,
    //       method: "GET",
    //     };
    //   },
    //   providesTags: ["GET-IMPORTANT-LINKS"],
    // }),
    // getImportantLinkPublic: builder.query({
    //   query: (userId: string) => {
    //     return {
    //       url: `/v2/client/public/user/important-links?userId=${userId}`,
    //       method: "GET",
    //     };
    //   },
    // }),
    updateImportantLinks: builder.mutation({
      query: (importantLinksData) => {
        return {
          url: `/v2/client/user/important-links`,
          method: "PUT",
          body: importantLinksData,
        };
      },
      invalidatesTags: ["GET-SECTION-DETAILS"],
    }),

    // getPersonalDetails: builder.query({
    //   query: (userId) => {
    //     return {
    //       url: `/v2/client/public/user/personal-details?userId=${userId}`,
    //       method: "GET",
    //     };
    //   },
    //   providesTags: ["GET-PERSONAL-DATA"],
    // }),

    getProfessionalSkillsData: builder.query({
      query: (userHash) => {
        return {
          url: `/v1/client/public/user/skill-progress?userHash=${userHash}`,
          method: "GET",
        };
      },
    }),

    // addPeronalDetails: builder.mutation({
    //   query: (personData) => {
    //     return {
    //       url: `/v2/client/user/personal-details`,
    //       method: "PUT",
    //       body: personData,
    //     };
    //   },
    //   invalidatesTags: ["GET-PERSONAL-DATA", "GET-PROFILE-COMPLETION-DATA"],
    // }),

    // getWorkExperience: builder.query<UserProfile['workExperience'], string>({
    //   query: (userId: string) => {
    //     return {
    //       url: `/v2/client/public/user/profile/work-experiences?userId=${userId}`,
    //       method: "GET",
    //     };
    //   },
    //   providesTags: ["GET-WORK-EXPERIENCE-DATA"],
    // }),

    // addWorkExperience: builder.mutation({
    //   query: (args) => {
    //     const { userId, formValues } = args;
    //     return {
    //       url: `/v2/client/user/profile/work-experience?userId=${userId}`,
    //       method: "POST",
    //       body: formValues,
    //     };
    //   },
    //   invalidatesTags: ["GET-SECTION-DETAILS"],
    // }),

    // updateWorkExperience: builder.mutation({
    //   query: (args) => {
    //     const { experienceId, formValues } = args;
    //     return {
    //       url: `/v2/client/user/profile/work-experience/${experienceId}`,
    //       method: "PUT",
    //       body: formValues,
    //     };
    //   },
    //   invalidatesTags: ["GET-SECTION-DETAILS"],
    // }),

    // deleteWorkExperience: builder.mutation({
    //   query: (args) => {
    //     const { experienceId } = args;
    //     return {
    //       url: `/v2/client/user/profile/work-experience/${experienceId}`,
    //       method: "DELETE",
    //     };
    //   },
    //   invalidatesTags: ["GET-SECTION-DETAILS"],
    // }),

    //  Publications sections
    createPublications: builder.mutation({
      query: (payload: {
        title: string;
        publisher: string;
        publicationDate: string;
        projectType: string;
        link: string;
        description: string;
      }) => ({
        url: `/v2/client/user/publication`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["GET-SECTION-DETAILS"],
    }),
    // getPublications: builder.query({
    //   query: (userId: string) => {
    //     return {
    //       url: `v2/client/user/publications?userId=${userId}`,
    //       method: "GET",
    //     };
    //   },
    //   providesTags: ["GET-PUBLICATIONS"],
    // }),
    deletePublications: builder.mutation({
      query: (publicationId: string) => {
        return {
          url: `v2/client/user/publication/${publicationId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["GET-SECTION-DETAILS"],
    }),
    // getPublicPublications: builder.query({
    //   query: (userId: string) => ({
    //     url: `/v2/client/public/user/publications?userId=${userId}`,
    //   }),
    //   providesTags: ["GET-PUBLIC-PUBLICATIONS"],
    // }),
    updatePublications: builder.mutation({
      query: ({
        publicationId,
        ...payload
      }: {
        publicationId: string;
        title: string;
        publisher: string;
        publicationDate: string;
        projectType: string;
        link: string;
        description: string;
      }) => ({
        url: `/v2/client/user/publication/${publicationId}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["GET-SECTION-DETAILS"],
    }),

    //Interests
    // getInterests: builder.query({
    //   query: (userId: string) => {
    //     return {
    //       url: `/v2/client/public/user/profile/interest?userId=${userId}`,
    //       method: "GET",
    //     };
    //   },
    //   providesTags: ["GET_INTERESTS_DATA"],
    // }),

    // updateInterests: builder.mutation({
    //   query: (args) => {
    //     const { userId, interests } = args;
    //     return {
    //       url: `/v2/client/user/profile/interests?${userId}`,
    //       method: "PUT",
    //       body: interests,
    //     };
    //   },
    //   invalidatesTags: ["GET-SECTION-DETAILS"],
    // }),

    //Education
    // getEducation: builder.query({
    //   query: (userId: string) => {
    //     return {
    //       url: `/v2/client/public/user/profile/education?userId=${userId}`,
    //       method: "GET",
    //     };
    //   },
    //   providesTags: ["GET-EDUCATION-DATA"],
    // }),

    // addEducation: builder.mutation({
    //   query: (args) => {
    //     const { userId, formValues } = args;
    //     return {
    //       url: `/v2/client/user/profile/education?userId=${userId}`,
    //       method: "POST",
    //       body: formValues,
    //     };
    //   },
    //   invalidatesTags: ["GET-SECTION-DETAILS"],
    // }),
    // updateEducation: builder.mutation({
    //   query: (args) => {
    //     const { educationId, formValues } = args;
    //     return {
    //       url: `/v2/client/user/profile/education/${educationId}`,
    //       method: "PUT",
    //       body: formValues,
    //     };
    //   },
    //   invalidatesTags: ["GET-SECTION-DETAILS"],
    // }),
    // deleteEducation: builder.mutation({
    //   query: (educationId: string) => ({
    //     url: `/v2/client/user/profile/education/${educationId}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["GET-SECTION-DETAILS"],
    // }),

    // honours and awards
    // createHonoursAndAwards: builder.mutation({
    //   query: (payload: { title: string; issuer: string; issueDate: string }) => ({
    //     url: `/v2/client/user/honour-awards`,
    //     method: "POST",
    //     body: payload,
    //   }),
    //   invalidatesTags: ["GET-SECTION-DETAILS"],
    // }),
    // getHonoursAndAwards: builder.query({
    //   query: (userId: string) => ({
    //     url: `/v2/client/user/honour-awards?userId=${userId}`,
    //     method: "GET",
    //   }),
    //   providesTags: ["GET-HONOURS-AND-AWARDS"],
    // }),
    // getHonoursAndAwardsById: builder.query({
    //   query: (honourId) => ({
    //     url: `/v2/client/user/honour-awards/${honourId}`,
    //     method: "GET",
    //   }),
    // }),
    // updateHonoursAndAward: builder.mutation({
    //   query: ({
    //     honourId,
    //     ...payload
    //   }: {
    //     honourId: string;
    //     title: string;
    //     issuer: string;
    //     issueDate: string;
    //   }) => ({
    //     url: `/v2/client/user/honour-awards/${honourId}`,
    //     method: "PUT",
    //     body: payload,
    //   }),
    //   invalidatesTags: ["GET-SECTION-DETAILS"],
    // }),
    // deleteHonoursAndAward: builder.mutation({
    //   query: (honourId: string) => ({
    //     url: `/v2/client/user/honour-awards/${honourId}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["GET-SECTION-DETAILS"],
    // }),

    // getPublicHonoursAndAward: builder.query({
    //   query: (userId: string) => ({
    //     url: `/v2/client/public/user/honour-awards?userId=${userId}`,
    //   }),
    //   providesTags: ["GET-PUBLIC-HONOURS-AND-AWARDS"],
    // }),

    // getPublicHonoursAndAwardById: builder.query({
    //   query: (payload: { honourId: string; userId: number }) => ({
    //     url: `v2/client/public/user/honour-awards/${payload.honourId}?honourId=${payload.userId}`,
    //   }),
    // }),

    getSkills: builder.query({
      query: (query: string) => ({
        url: `v1/client/public/lms/autocomplete-skills?skillName=${query}`,
      }),
    }),

    getSectionDetails: builder.query<UserProfile, { sectionId?: string; userHash: string }>({
      query: (params) => ({
        url: "/v2/client/public/user/details",
        params,
        method: "GET",
      }),
      providesTags: ["GET-SECTION-DETAILS"],
    }),

    createSectionDetails: builder.mutation<UserProfile, { sectionId?: string; body: any }>({
      query: ({ body, ...params }) => ({
        url: "/v2/client/user/details",
        params,
        body,
        method: "POST",
      }),
      invalidatesTags: ["GET-SECTION-DETAILS"],
    }),

    updateSectionDetails: builder.mutation<
      UserProfile,
      { sectionId?: string; objectId?: string; body: Record<string, any> }
    >({
      query: ({ body, ...params }) => ({
        url: "/v2/client/user/details",
        params,
        body,
        method: "PUT",
      }),
      invalidatesTags: ["GET-SECTION-DETAILS"],
    }),

    deleteSectionObject: builder.mutation<void, { sectionId: string; objectId: string }>({
      query: (params) => ({
        url: "/v2/client/user/details",
        params,
        method: "DELETE",
      }),
      invalidatesTags: ["GET-SECTION-DETAILS"],
    }),
  }),
});

export const {
  useGetBadgesQuery,
  useGetGivenRecommendationStatusQuery,
  useAddNewRecommendationMutation,
  useEditExistingRecommendationMutation,
  useGetAllRecommendationsQuery,
  useGetAllPublicRecommendationsQuery,
  useAcceptDeleteRecommendationMutation,
  useShowHideRecommendationMutation,
  useUpvoteRecommendationMutation,
  // useGetBasicDetailsDataQuery,
  // useUpdateBasicDetailsMutation,
  useApplaudUserMutation,
  useRemoveApplaudMutation,
  useGetProfileCompletionDataQuery,
  useUpdateProfileCompletionDataMutation,
  // useGetImportantLinkQuery,
  // useGetImportantLinkPublicQuery,
  useUpdateImportantLinksMutation,
  useGetUserDataQuery,
  useGetSoftwareKnowledgeSkillsQuery,
  // useGetCertificatesQuery,
  // useGetCertificateByIdQuery,
  // useGetPublicCertificateByIdQuery,
  // useGetPublicCertificatesQuery,
  // useCreateCertificateMutation,
  // useUpdateCertificateMutation,
  // useDeleteCertificateMutation,
  useSomeChangeMutation,
  // useAddPeronalDetailsMutation,
  // useAddWorkExperienceMutation,
  // useGetWorkExperienceQuery,
  // useUpdateWorkExperienceMutation,
  // useDeleteWorkExperienceMutation,
  // useGetPersonalDetailsQuery,
  useGetProfessionalSkillsDataQuery,
  // useCreateHonoursAndAwardsMutation,
  // useUpdateHonoursAndAwardMutation,
  // useCreatePublicationsMutation,
  // useGetPublicationsQuery,
  // useDeletePublicationsMutation,
  // useGetPublicPublicationsQuery,
  // useGetHonoursAndAwardsQuery,
  // useGetHonoursAndAwardsByIdQuery,
  // useDeleteHonoursAndAwardMutation,
  // useGetPublicHonoursAndAwardQuery,
  // useGetPublicHonoursAndAwardByIdQuery,
  useUpdatePublicationsMutation,
  // useGetEducationQuery,
  useGetSkillsQuery,
  // useAddEducationMutation,
  // useUpdateEducationMutation,
  // useDeleteEducationMutation,
  // useGetInterestsQuery,
  // useUpdateInterestsMutation,
  useGetSectionDetailsQuery,
  useUpdateSectionDetailsMutation,
  useLazyGetSectionDetailsQuery,
  useDeleteSectionObjectMutation,
  useCreateSectionDetailsMutation,
} = userProfileClient;
