import Link from "next/link";
import styled from "styled-components";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import { IconTypes } from "@/design-system/Icon/types";

import LinkButton from "@/design-system-v2/Button/LinkButton";
import FlexContainer from "@/design-system-v2/FlexContainer";
import Typography from "@/design-system-v2/Typography";

import { useAppSelector } from "@/hooks/hooks";

import { theme } from "@/theme";

import ReferBanner from "../ReferBanner";

const SidebarWrapper = styled(Box)`
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
  min-width: 350px;
  max-width: 350px;
  position: sticky;
  top: 0;
  height: 100vh;
  padding-right: 120px;
  max-width: 350px;

  .link {
    padding: 0px;
    height: auto;
    margin-bottom: ${theme.spacing(6)};
    justify-content: flex-start;

    .icon {
      margin-right: ${theme.spacing(3)};
    }
  }
`;

const Sidebar = ({
  usedInMentorhub,
  usedInLearnerHub,
  showReferBanner,
  placementHubCohortId,
}: {
  usedInMentorhub?: boolean;
  usedInLearnerHub?: boolean;
  showReferBanner?: boolean;
  placementHubCohortId?: string;
}) => {
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);
  const links = usedInMentorhub
    ? [
      {
        text: "My Earnings",
        icon: "wallet-02",
        link: "lm/earnings",
      },
      {
        text: "My Impact",
        icon: "bar-chart-01",
        link: "lm/impact",
      },
    ]
    : usedInLearnerHub
      ? [
        {
          text: "Profile",
          icon: "user-03",
          link: `/user-profile/${loginDetails.userProfile.userHash}`,
        },
        {
          text: "My Applications",
          icon: "list",
          link: "my-application",
        },
        {
          text: "My Orders",
          icon: "currency-dollar-circle",
          link: "billing",
        },
        ...(placementHubCohortId
          ? [
            {
              text: "Placement Hub",
              icon: "diamond-01",
              link: `placement-hub?cohortId=${placementHubCohortId}`,
            },
          ]
          : []),
      ]
      : [
        {
          text: "My Earnings",
          icon: "wallet-02",
          link: "mentor/earnings",
        },
      ];

  return (
    <SidebarWrapper
      px={theme.spacing(6)}
      py={theme.spacing(8)}
      bg={theme.colors.grayCool[100]}
      maxWidth={"350px"}
    >
      <FlexContainer px={16} flexDirection={"column"}>
        <Typography
          varient="textmd"
          weightVarient="medium"
          px={theme.spacing(2)}
          color={theme.colors.grayCool[600]}
        >
          {usedInMentorhub ? "Mentor Hub" : usedInLearnerHub ? "Quick Links" : "IG Hub"}
        </Typography>
        <Box my={theme.spacing(8)} height={"1px"} bg={theme.colors.grayCool[300]} />
        <Box px={theme.spacing(3)} py={theme.spacing(2)}>
          {links.map((link, index) => (
            <Link key={index} href={link.link}>
              <LinkButton
                key={index}
                className="link"
                color="neutral"
                label={link.text}
                size={"lg"}
                fullWidth={true}
                icon={<Icon varient="line" type={link.icon as IconTypes} />}
              />
            </Link>
          ))}
        </Box>
      </FlexContainer>
      {showReferBanner && <ReferBanner />}
    </SidebarWrapper>
  );
};

export default Sidebar;
