import Router from "next/router";

export const redirectionUrl = (roles = []) => {
  const isLeadMentor = roles?.includes("LEAD_MENTOR");
  const isIg = roles?.includes("INDUSTRIAL_GUIDE");
  if (isLeadMentor) {
    return "/mentor-hub";
  } else if (isIg) {
    return "/ig-hub";
  } else {
    return "/learner-hub";
  }
};
/**
 * This function extracts a callback URL and additional query parameters from the
 * router query object and constructs a new URL with the query parameters appended appropriately.
 * @returns url if callbackUrl exists, otherwise undefined
 */
const getCallbackUrl = () => {
  // The 'callbackUrl' key may exist when user gets auto logout
  // in case of INVALID_TOKEN. We need to redirect where the user came from.
  const { callbackUrl, ...rest } = Router.query;

  if (!callbackUrl) return;

  // convert rest object into query string
  const queryParamString = rest
    ? Object.entries(rest)
      .map(([key, value]) => `${key}=${value}`)
      .join("&")
    : "";

  if (!queryParamString) return callbackUrl;

  if (callbackUrl.indexOf("?") === -1) return `${callbackUrl}?${queryParamString}`;

  return `${callbackUrl}&${queryParamString}`;
};

export const redirectUrlAfterLogin = (userDataRes, userProfileRes) => {
  const callbackUrl = getCallbackUrl();

  let url: string = (callbackUrl as string) || redirectionUrl(userDataRes?.roles);
  if (
    !callbackUrl &&
    userDataRes.roles &&
    (userDataRes.roles.includes("INDUSTRIAL_GUIDE") || userDataRes.roles.includes("LEAD_MENTOR"))
  ) {
    if (userProfileRes.profileStatus !== "COMPLETED") {
      if (userProfileRes.profileStatus.includes("FILLED")) {
        url = `/user-profile/${userProfileRes.userHash}`;
      } else {
        url = "/onboarding/mentor";
      }
    }
  }
  Router.replace(url);
};
