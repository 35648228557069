export enum AttachmentType {
  LINK = "LINK",
  FILE = "FILE",
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  FORM = "FORM",
  VIDEO = "VIDEO",
  TILE_IMAGE = "TILE_IMAGE",
}

export enum UploadType {
  WEBSITE_PROFILE = "WEBSITE_PROFILE",
  INTRODUCTION_VIDEO = "INTRODUCTION_VIDEO",
  DOCUMENT = "DOCUMENT",
}

export interface S3Bucket {
  bucket: string;
  path: string;
}

export interface Attachment {
  type?: AttachmentType;
  s3Location?: S3Bucket;
  key: UploadType;
}

export interface UploadDocument extends S3Bucket {
  url: string;
  contentType: string;
  uploadType: UploadType;
}
